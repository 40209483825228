import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import One2PayAuthProvider from './security/OneTwoPayAuthProvider';
import {BrowserRouter} from "react-router-dom";
import OneTwoPayApolloProvider from "./graphql/OneTwoPayApolloProvider";
import {ThemeProvider} from '@mui/material/styles';
import theme from "./theme";
import {pdfjs} from 'react-pdf';
import SilentLogin from "./security/SilentLogin";
import AnonUserProvider from "./graphql/AnonUserProvider";
import UserProfile from "./security/UserProfile";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import Layout from "./components/Layout";
import UserContext from "./components/User/UserContext";
import AssumedUserContext from "./components/User/AssumedUserContext";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <One2PayAuthProvider>
                <SilentLogin>
                    <OneTwoPayApolloProvider>
                        <ThemeProvider theme={theme}>
                            <AnonUserProvider>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <UserContext>
                                        <AssumedUserContext>
                                            <Layout>
                                                <UserProfile>
                                                    <App/>
                                                </UserProfile>
                                            </Layout>
                                        </AssumedUserContext>
                                    </UserContext>
                                </LocalizationProvider>
                            </AnonUserProvider>
                        </ThemeProvider>
                    </OneTwoPayApolloProvider>
                </SilentLogin>
            </One2PayAuthProvider>
        </BrowserRouter>
    </React.StrictMode>
);
