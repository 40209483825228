import {Button, Grid, MenuItem, TextField} from "@mui/material";
import React from "react";
import {useForm} from "react-hook-form";
import {Employer, EmployerInput, useSaveEmployerMutation} from "../../graphql/generated/graphql";
import useAnonCookie from "../../security/useAnonCookie";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import useGetProvinces from "../../Utils/useGetProvinces";
import GET_EMPLOYERS from "../../graphql/queries/getEmployers";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import usePayStubIdManager from "../PayStub/usePayStubIdManager";
import ReactFormSwitch from "../../common/ReactFormSwitch";

type PropsType = {
    close: () => void,
    employer: Employer;
    employerUpdated: (employerId: string) => void,
}

function initializeFormValues(employer: Employer): EmployerInput {
    return {
        id: employer.id,
        name: employer.name,
        address1: employer.address1,
        address2: employer.address2,
        city: employer.city,
        postalCode: employer.postalCode,
        province: employer.province,
        applyToExistingStubs: !!employer.id
    }
}

const EditEmployerForm = (props: PropsType) => {

    const {close, employer, employerUpdated} = props;

    const {getPayStubId} = usePayStubIdManager();

    const {
        register,
        handleSubmit,
        formState: {errors},
        control
    } = useForm<EmployerInput>({defaultValues: initializeFormValues(employer)});
    const {getAnonUserId} = useAnonCookie();
    const [
        saveEmployerToServer,
        {
            loading: saveEmployerLoading,
            error: saveEmployerError
        }
    ] = useSaveEmployerMutation();


    const {
        provinces,
        error: provincesError,
        loading: provincesLoading
    } = useGetProvinces();

    const saveEmployer = (data: EmployerInput) => {
        saveEmployerToServer({
            variables: {
                employer: data,
                payStubId: getPayStubId(),
                anonUserId: getAnonUserId()
            },
            refetchQueries: [
                {
                    query: GET_EMPLOYERS,
                    variables: {
                        anonUserId: getAnonUserId(),
                    },
                }
            ]
        })
            .then(saveResult => {
                if (saveResult.data) {
                    const savedEmployer = saveResult.data.saveEmployer;
                    employerUpdated(savedEmployer.id.toString());
                    close();
                }
            })
            .catch(/*handled by the error object.*/)
    }

    return (
        <form noValidate onSubmit={handleSubmit(saveEmployer)}>
            <Grid container alignItems="center" spacing={1.5}>
                <Grid container spacing={1} justifyContent={"start"} sx={{mt: 1.5, ml: .25}}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={"Employer Name"}
                            {...register("name", {required: "Name is required"})}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Address 1"}
                        {...register("address1")}
                        error={!!errors.address1}
                        helperText={errors.address1?.message}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={"Address 2"}
                        {...register("address2")}
                        error={!!errors.address2}
                        helperText={errors.address2?.message}
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <TextField
                        fullWidth
                        label={"City"}
                        {...register("city")}
                        error={!!errors.city}
                        helperText={errors.city?.message}
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    {!!provinces &&
                        <ReactHookFormSelect<EmployerInput>
                            nameOfFormField={"province"}
                            label={"Province"}
                            control={control}
                        >
                            {provinces.map((province) => (
                                <MenuItem key={province.key} value={province.key}>
                                    {province.label}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                    }
                    <LoadingErrorDisplay
                        loading={provincesLoading}
                        stringError={errors.province?.message}
                        apolloError={provincesError}
                    />

                </Grid>
                <Grid item xs={8} sm={4}>
                    <TextField
                        fullWidth
                        label={"Postal Code"}
                        {...register("postalCode", {
                            pattern: {
                                value: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/,
                                message: "Invalid Canadian Postal Code format"
                            }
                        })}
                        error={!!errors.postalCode}
                        helperText={errors.postalCode?.message}
                    />
                </Grid>
                <Grid item xs={8} sm={4}>
                    <ReactFormSwitch
                        fieldName={'applyToExistingStubs'}
                        label={'Apply update to existing pay stubs'}
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingErrorDisplay
                        loading={saveEmployerLoading}
                        apolloError={saveEmployerError}
                    >
                        <Button
                            onClick={() => close()}
                            style={{fontSize: '1.2rem'}}>
                            Cancel
                        </Button>
                        <Button
                            type={"submit"}
                            style={{fontSize: '1.2rem'}}
                        >
                            Save
                        </Button>
                    </LoadingErrorDisplay>
                </Grid>
            </Grid>
        </form>
    );
}

export default EditEmployerForm;