import * as React from "react";
import {createContext, ReactNode, useCallback, useEffect, useState} from "react";
import {useGetSignedInUserLazyQuery, User} from "../../graphql/generated/graphql";
import {useAuth0} from "@auth0/auth0-react";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";


type PropsType = {
    children: ReactNode;
}

export const DEFAULT_USER_PROFILE: User = {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    auth0Id: "",
    mobile: "",
    provinceCode: "",
    createdDate: "",
    isAdmin: false

}

type ContextType<T> = {
    user: T;
    refreshUser: () => void;
};


const defaultContext: ContextType<User> = {
    user: DEFAULT_USER_PROFILE,
    refreshUser: () => {
    },
};

export const userContext = createContext<ContextType<typeof DEFAULT_USER_PROFILE>>(defaultContext);

const UserContext = (props: PropsType) => {
    const {children} = props;
    const {
        isAuthenticated,
        isLoading: authLoading
    } = useAuth0();
    const {sendNotice} = useSystemNotices();
    const [signedInCheckDone, setSignedInCheckDone] = useState(false);
    const [userProfile, setUserProfile] = useState<User>(DEFAULT_USER_PROFILE);
    const [
        getSignedInUser,
        {
            loading: signedInUserLoading,
            error: signedInUserError
        }] = useGetSignedInUserLazyQuery();

    const getUser = useCallback((fetchPolicy: "network-only" | "cache-first") => {
        setSignedInCheckDone(false);
        getSignedInUser({
            fetchPolicy: fetchPolicy
        })
            .then(result => {
                const signedInUser = result.data?.getSignedInUser;
                if (signedInUser) {
                    setUserProfile(signedInUser);
                }
                setSignedInCheckDone(true);
            })
            .catch(error => {
                setSignedInCheckDone(true);
                sendNotice(`Error in UserContext. Error returned from getSignedInUser: ${error.message}`);
            });
    }, [getSignedInUser, sendNotice]);

    useEffect(() => {
        if (authLoading) {
            return;
        }
        if (!isAuthenticated) {
            setSignedInCheckDone(true);
            return;
        }
        if (signedInCheckDone) {
            return;
        }
        if (signedInUserLoading) {
            return;
        }
        if (!!signedInUserError) {
            sendNotice(`Error in UserContext. Error returned from getSignedInUser: ${signedInUserError.message}`);
            setSignedInCheckDone(true);
            return;
        }
        getUser("cache-first");

    }, [getUser, signedInCheckDone, sendNotice, signedInUserError, signedInUserLoading, authLoading, isAuthenticated]);


    if (!signedInCheckDone) {
        return <LoadingErrorDisplay
            loading={!signedInCheckDone}
            loadingMessage={"Loading user"}
        />
    }

    return (
        <userContext.Provider
            value={{
                user: userProfile,
                refreshUser: () => getUser("network-only"),
            }}
        >
            {children}
        </userContext.Provider>
    )

}
export default UserContext;