import React, {useCallback, useContext, useEffect} from "react";
import {Typography} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import useSystemNotices from "../Utils/useSystemNotices";
import {userContext} from "../components/User/UserContext";
import {useNavigate} from "react-router-dom";

let signUpPrompted = false;
const Signup = () => {

    const {isLoading, isAuthenticated, loginWithPopup} = useAuth0();
    const {user} = useContext(userContext);
    const {sendNotice} = useSystemNotices();
    const navigate = useNavigate();

    const signUp = useCallback(() => {
        signUpPrompted = true;
        loginWithPopup({ // todo: pick it up here. does safari allow a pop up from Auth0?
            authorizationParams: {
                screen_hint: 'signup',
            }
        })
            .catch(error => {
                console.error(error);
                sendNotice(`Error thrown by loginWithPopup on Signup page. Error: ${error.message}`);
            });
    }, [loginWithPopup, sendNotice]);

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (!!user && !!user.id && user.id > 0) {
            navigate("/employees");
            return;
        } else if (!isAuthenticated && !signUpPrompted) {
            signUp();
            return;
        } else {
            sendNotice("On signup page and hit a condition that should not be possible. User not populated with id but the user is authenticated. " +
                "In this case, the UserProfile page should intercept and force the user to create a profile. Which would then cause the user to have an id and this page would redirect to My Account.");
            navigate("/pay-stub");
        }
    }, [signUpPrompted, isAuthenticated, user, signUp, sendNotice, navigate, isLoading]);

    return <Typography>
        Signing up for OneTwoPay
    </Typography>
}
export default Signup;