import React from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import PayrollHistory from './Views/PayrollHistory';
import {RequireAuth} from './security/RequireAuth';
import './common.scss';
import Reports from "./Views/Reports";
import {ReportPagePath} from "./graphql/generated/graphql";
import Employers from "./Views/Employers";

import {LicenseInfo} from '@mui/x-license';
import TrustPilotInvite from "./Views/TrustPilotInvite";
import PayStubDisplayEdit from "./Views/PayStubDisplayEdit";
import Employees from "./Views/Employees";
import PayStubWithAccessCheck from "./Views/PayStubWithAccessCheck";
import Signup from "./Views/Signup";


function App() {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENCE_KEY || "");

    return (
        <Routes>
            <Route
                path={"/"}
                element={
                    <RequireAuth component={Employees}/>
                }
            />
            <Route
                path={"/employees"}
                element={
                    <RequireAuth component={Employees}/>
                }
            />
            <Route
                path={"/signup"}
                element={
                    <Signup/>
                }
            />
            <Route
                path={`reports/${ReportPagePath.Payrollhistory}`}
                element={
                    <RequireAuth component={PayrollHistory}/>
                }
            />
            <Route
                path="/pay-stub/:paramsPayStubId"
                element={
                    <PayStubWithAccessCheck/>
                }
            />
            <Route
                path="/pay-stub"
                element={
                    <PayStubDisplayEdit/>
                }
            />
            <Route
                path="/reports"
                element={
                    <RequireAuth component={Reports}/>
                }
            />
            <Route
                path="/employers/:employerId"
                element={
                    <RequireAuth component={Employers}/>
                }
            />
            <Route
                path="/employers"
                element={
                    <RequireAuth component={Employers}/>
                }
            />
            <Route
                path="/trustpilot"
                element={
                    <RequireAuth component={TrustPilotInvite}/>
                }
            />
        </Routes>

    );
}

export default App;
