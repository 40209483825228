import {Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {GetEmployerQuery, useGetEmployerLazyQuery} from "../../graphql/generated/graphql";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import EmployerSelectEdit from "./EmployerSelectEdit";
import useAnonCookie from "../../security/useAnonCookie";
import EmployerDisplayWithLogo from "./EmployerDisplayWithLogo";
import {getNumber} from "../../Utils/stringMath";
import usePayStubIdManager from "../PayStub/usePayStubIdManager";


function newEmployerDataNeeded(employerId: number | undefined | null, employerData: GetEmployerQuery | undefined) {
    const employerIdExists = !!employerId && getNumber(employerId) > 0;
    const employerDataCurrent = !!employerData && employerData.getEmployer.id === employerId;
    return employerIdExists || !employerDataCurrent;
}

type PropsType = {
    employerId: number | undefined | null;
    employerUpdated: (employerId: string) => void;
    isEditable: boolean;
    clearEmployer: () => void;
}

const EmployerDisplayEdit = (props: PropsType) => {
    const {employerId, employerUpdated, isEditable, clearEmployer} = props;
    const [editMode, setEditMode] = useState(false);
    const {getAnonUserId} = useAnonCookie();
    const {getPayStubId} = usePayStubIdManager();

    const [
        getEmployer,
        {
            data: employerData,
            loading: employerLoading,
            error: employerError
        }
    ] = useGetEmployerLazyQuery();


    useEffect(() => {
        if (!employerId) {
            return;
        }
        if (newEmployerDataNeeded(employerId, employerData) && !employerLoading && !employerError) {
            let anonUserId = getAnonUserId();
            getEmployer({
                variables: {
                    employerId: employerId,
                    anonUserId: anonUserId
                }
            })
                .then(() => {/*do nothing. response captured in employerData object */
                });
        }
    }, [employerId, getEmployer, employerData, employerLoading, employerError, getAnonUserId, getPayStubId]);

    const employer = employerData?.getEmployer;
    if (editMode) {
        return <EmployerSelectEdit
            close={() => setEditMode(false)}
            employer={employer}
            employerUpdated={employerUpdated}
            isEditable={isEditable}
            clearEmployer={clearEmployer}
        />;
    }
    const noEmployerDisplay: ReactJSXElement = <Button
        type={"button"}
        variant="contained"
        size="large"
        onClick={() => setEditMode(true)}
    >
        Add employer
    </Button>;

    return <EmployerDisplayWithLogo
        employer={employer}
        edit={() => setEditMode(true)}
        noEmployerDisplay={noEmployerDisplay}
        employerUpdated={employerUpdated}
        isEditable={isEditable}
    />;
}
export default EmployerDisplayEdit;