import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Employer, useGetEmployersLazyQuery} from "../../graphql/generated/graphql";
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import EmployerDisplay from "./EmployerDisplay";
import EditEmployerForm from "./EditEmployerForm";
import {getNumber} from "../../Utils/stringMath";
import useAnonCookie from "../../security/useAnonCookie";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useSystemNotices from "../../Utils/useSystemNotices";

type EmployerAutoComplete = {
    label: string;
    employer: Employer
}

const employersAreEqual = (option: EmployerAutoComplete, value: EmployerAutoComplete) => {
    if (!value) {
        return false;
    }
    return option.employer.id === value.employer.id;
}

const getEmployerAutoComplete = (employer: Employer): EmployerAutoComplete => {
    return {
        label: employer.detailedName,
        employer: employer
    }
}
const getEmployerAutoCompleteOrUndefined = (employer: Employer | undefined): EmployerAutoComplete | undefined => {
    if (!employer) {
        return {
            label: '',
            employer: getEmptyEmployer()
        };
    }
    return getEmployerAutoComplete(employer);
}
const getEmployerOptions = (employers: Array<Employer>): Array<EmployerAutoComplete> => {
    let employerOptions = employers.map(employer => {
        return getEmployerAutoComplete(employer);
        }
    );
    const newEmployerOption: EmployerAutoComplete = {label: "Add A New Employer +", employer: getEmptyEmployer()};
    employerOptions.push(newEmployerOption);
    return employerOptions;
}
export const getEmptyEmployer = (): Employer => {
    return {
        id: 0,
        name: "",
        detailedName: "",
        address1: undefined,
        address2: undefined,
        city: undefined,
        postalCode: undefined,
        province: "ON",
    }
}

function employerHasId(employerOrNew: Employer | undefined) {
    return !!employerOrNew && !!employerOrNew.id && getNumber(employerOrNew.id) > 0;
}

type PropsType = {
    close: () => void,
    employer: Employer | undefined,
    employerUpdated: (employerId: string) => void,
    isEditable: boolean;
    clearEmployer: () => void;
}


const EmployerSelectEdit = (props: PropsType) => {

    const {close, employer, employerUpdated, isEditable} = props;
    const [editEmployer, setEditEmployer] = useState<boolean>(false);
    const [employerOptions, setEmployerOptions] = useState<Array<EmployerAutoComplete>>([]);
    const [employerOrNew, setEmployerOrNew] = useState<Employer | undefined>(employer);
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();

    const [
        getEmployers,
        {
            data: employersData,
            loading: employersLoading,
            error: employersError
        }] = useGetEmployersLazyQuery({variables: {anonUserId: getAnonUserId()}});


    useEffect(() => {
        if (!!employersData && !!employerOrNew && !employerHasId(employerOrNew)) {
            setEditEmployer(true);
        }
    }, [employerOrNew, employersData]);


    useEffect(() => {
        if (!employersData && !employersLoading && !employersError) {
            getEmployers()
                .then(result => {
                    let data = result.data;
                    if (!data) {
                        sendNotice("Error getting employers in Employer select. getEmployers did not return any data.");
                    } else if (!!data.getEmployers && data.getEmployers.length > 0) {
                        setEmployerOptions(getEmployerOptions(data.getEmployers));
                        let employerOrNewHasId = employerHasId(employerOrNew);
                        if (!employerOrNewHasId) {
                            setEmployerOrNew((data.getEmployers)[0]);
                        }
                    } else if (!!data.getEmployers && data.getEmployers.length < 1) {
                        setEmployerOrNew(getEmptyEmployer());
                        setEditEmployer(true);
                    }
                })
                .catch(error => {
                    sendNotice(`Error getting employers: ${error.message}`);
                });
        }
    }, [employerOrNew, getEmployers, sendNotice, employersData, employersLoading, employersError]);


    function selectEmployer(newValue: Employer) {
        employerUpdated(newValue.id.toString());
        close();
    }

    const noEmployerDisplay: ReactJSXElement = <Typography>Please select an employer or create a new one.</Typography>;
    const displayEmployer: ReactJSXElement =
        <EmployerDisplay
            employer={employerOrNew}
            edit={() => setEditEmployer(true)}
            noEmployerDisplay={noEmployerDisplay}
            isEditable={isEditable}
        />;


    let dialogContentElement = displayEmployer;
    if (editEmployer) {
        dialogContentElement = <EditEmployerForm
            close={close}
            employer={employerOrNew || getEmptyEmployer()}
            employerUpdated={employerUpdated}
        />;
    }

    let employerSelectElement = <>
        <Grid container alignItems="center" sx={{mt: 2}}>
            <Grid item xs={12}>
                {
                    !editEmployer &&
                    <Autocomplete
                        disabled={editEmployer}
                        disablePortal
                        options={employerOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select Employer"
                                fullWidth
                                InputLabelProps={{
                                    style: {fontSize: '1.6rem'},
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: {fontSize: '1.4rem'},
                                }}
                                sx={{
                                    mt: 3,
                                    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                                        backgroundColor: 'white',
                                        paddingRight: '5px',
                                    },
                                }}
                            />
                        )}
                        value={getEmployerAutoCompleteOrUndefined(employerOrNew)}
                        isOptionEqualToValue={employersAreEqual}
                        getOptionLabel={(option) => option.label}
                        onChange={(event: any, newValue: any) => {
                            console.log("event: ", event.toString());
                            if (!!newValue) {
                                setEmployerOrNew(newValue.employer);
                            }
                        }}
                        renderOption={(props, option) => (
                            <li {...props} style={{fontSize: '1.4rem'}}>
                                {option.label}
                            </li>
                        )}
                    />
                }
            </Grid>

        </Grid>
    </>;

    if (employersLoading || !!employersError) {
        return <LoadingErrorDisplay
            loading={employersLoading}
            apolloError={employersError}
        />
    }

    return <Dialog open={true} fullWidth={true}
                   sx={{
                       mt: 5,
                       '& .MuiDialog-paper': {
                           minWidth: {
                               xs: '95vw',
                               sm: '80vw',
                               md: '65vw',
                               lg: '20vw'
                           },
                       },
                   }}>
        <DialogTitle>
            {
                !!employerOptions && employerOptions.length > 0 &&
                employerSelectElement
            }
            <IconButton
                edge="end"
                color="inherit"
                onClick={close}
                aria-label="close"
                sx={{
                    position: 'absolute',
                    top: 1,
                    right: 15,
                }}
            >
                <CloseIcon fontSize={"large"}/>
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {dialogContentElement}
            <Divider sx={{mt: 2, mb: 0}}/>
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
                <Grid item xs={12} sm={6}>
                    {!editEmployer && <Button
                        onClick={() => setEmployerOrNew(getEmptyEmployer())}
                        variant='outlined'
                        size={"large"}
                        sx={{mt: 3}}
                    >
                        Add New Employer
                    </Button>
                    }
                </Grid>
                <Grid item xs={12} sm={5} md={4} container direction="row"
                      justifyContent={{xs: "flex-start", sm: "flex-end"}} flexGrow={1}>
                    {!editEmployer && !!employerOrNew && employerHasId(employerOrNew) && <Button
                        onClick={() => selectEmployer(employerOrNew)}
                        variant='contained'
                        size={"large"}
                        fullWidth={true}
                        sx={{mt: 3}}
                        disabled={!employerHasId(employerOrNew)}
                    >
                        Save
                    </Button>
                    }
                </Grid>
            </Grid>
        </DialogContent>
    </Dialog>
}

export default EmployerSelectEdit;