import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import {Box, Grid, Typography} from "@mui/material";
import {Employee} from "../../graphql/generated/graphql";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import useGetProvinces from "../../Utils/useGetProvinces";
import useGetPayFrequencies from "../../Utils/useGetPayFrequencies";
import useGetClaimCodes from "../../Utils/useGetClaimCodes";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";

type PropsType = {
    employee?: Employee;
    edit: () => void;
    noEmployeeDisplay: ReactJSXElement;
    isEditable: boolean;
}

const EmployeeDisplay = (props: PropsType) => {
    const {employee, noEmployeeDisplay, edit, isEditable} = props;

    const gridStyle = {
        "@media (max-width: 960px)": {minWidth: "auto",},
        "@media (min-width: 961px) and (max-width: 1400px)": {minWidth: "auto",},
        maxWidth: "100%",
    };

    const {
        provinces,
        error: provincesError,
        loading: provincesLoading
    } = useGetProvinces();
    const {
        payFrequencies,
        error: payFrequenciesError,
        loading: payFrequenciesLoading
    } = useGetPayFrequencies();
    const {
        claimCodes,
        error: claimCodesError,
        loading: claimCodesLoading
    } = useGetClaimCodes();

    if (!employee || employee.id < 1) {
        return noEmployeeDisplay;
    }

    const loading = provincesLoading || payFrequenciesLoading || claimCodesLoading;
    const error = provincesError || payFrequenciesError || claimCodesError;

    if (loading || error) {
        return <LoadingErrorDisplay
            loading={loading}
            apolloError={error}
        />
    }

    const employmentProvince = provinces?.find(province => province.key === employee.employmentProvince);
    const payFrequency = payFrequencies?.find(payFrequency => payFrequency.key === employee.payFrequency);
    const federalClaimCode = claimCodes?.find(claimCode => claimCode.key === employee.federalClaimCode);
    const provincialClaimCode = claimCodes?.find(claimCode => claimCode.key === employee.provincialClaimCode);
    const eiExempt = employee.eiExempt ? "Yes" : "No";
    const cppExempt = employee.cppExempt ? "Yes" : "No";

    return <Grid display="flex" alignItems="left" sx={gridStyle}>

        <Grid item xs={12} justifyContent={{xs: "flex-start"}}>
            <Box ml={2} textAlign={{xs: "left"}}>

                <Grid container direction={'row'}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={'h4'}>
                            {employee.firstName} {employee.lastName}
                            {isEditable &&
                                <OneTwoPayTooltip tipContent={"Edit employee"}>
                                    <EditIcon
                                        color={"primary"}
                                        sx={{ml: 2}}
                                        onClick={() => edit()}
                                    />
                                </OneTwoPayTooltip>
                            }
                        </Typography>
                        <Typography variant={'body1'}>{employee.address1}</Typography>
                        <Typography variant={'body1'}>{employee.address2}</Typography>
                        <Typography variant={'body1'}>
                            {employee.city}, {employee.province}, {employee.postalCode?.toUpperCase()}
                        </Typography>
                        <Typography
                            variant={"body1"}
                            sx={{mt: 1.5}}
                        >Employee Id: {employee.companyUserId}</Typography>
                        <Typography variant={'body1'}>Pay frequency: <strong>{payFrequency?.label}</strong></Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={'body1'}>Employment province: <strong>{employmentProvince?.label}</strong></Typography>
                        <Typography
                            sx={{mt: 1}}
                            variant={'body1'}
                        >Federal claim code: {federalClaimCode?.label}</Typography>
                        <Typography variant={'body1'}>Provincial claim code: {provincialClaimCode?.label}</Typography>
                        <Typography
                            sx={{mt: 1}}
                            variant={'body1'}>EI exempt: {eiExempt}</Typography>
                        <Typography variant={'body1'}>CPP exempt: {cppExempt}</Typography>
                    </Grid>
                </Grid>

            </Box>
        </Grid>
    </Grid>
};

export default EmployeeDisplay;