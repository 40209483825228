import * as React from 'react';
import {ReactNode, useContext, useEffect, useState} from 'react';

import UserProfileDialog from "../common/UserProfileDialog";
import {REACT_CLIENT_APP_PREFERENCE, useSaveAppPreference} from "../common/useSaveAppPreference";
import {userContext} from "../components/User/UserContext";


type PropsType = {
    children: ReactNode;
}

const UserProfile = (props: PropsType) => {

    const [profileCreateRequired, setProfileCreateRequired] = useState(false);
    const {user, refreshUser} = useContext(userContext);

    const {saveAppPreference} = useSaveAppPreference();

    useEffect(() => {
        if (!!user.email && !user.id) {
            setProfileCreateRequired(true);
        }
    }, [user]);

    if (profileCreateRequired) {
        return <UserProfileDialog
            userProfile={user}
            profileCreated={userProfile => {
                setProfileCreateRequired(false);
                refreshUser();
                saveAppPreference(REACT_CLIENT_APP_PREFERENCE, userProfile.email);
            }}
        />
    }

    return <>
        {props.children}
    </>
}
export default UserProfile;