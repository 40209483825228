import {Button, Dialog, DialogContent, DialogTitle, Divider, Grid, MenuItem, Select, TextField} from "@mui/material";
import React, {useCallback, useState} from "react";
import {useForm} from "react-hook-form";
import {useGetUsersLazyQuery, User, UserSearchParams} from "../../graphql/generated/graphql";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useSystemNotices from "../../Utils/useSystemNotices";
import {getNumber} from "../../Utils/stringMath";

type PropsType = {
    setUser: (user: User) => void;
    user: User | undefined;
    signedInUser: User;
    open: boolean;
    close: () => void;
}


function getFirstUserInList(usersData: User[] | undefined) {
    if (!usersData || usersData.length < 1) {
        return undefined;
    }
    return usersData[0];
}

function getUserForId(userId: string | number | null | undefined, selectableUsers: User[] | undefined) {
    if (!selectableUsers || !userId) {
        return undefined;
    }
    return selectableUsers.find(user => user.id === getNumber(userId));
}

const UserSearch = (props: PropsType) => {
    const {setUser, user, signedInUser, open, close} = props;
    const [error, setError] = useState<string>();
    const [selectedUser, setSelectedUser] = useState<User>();
    const {sendNotice} = useSystemNotices();

    const {
        handleSubmit,
        register,
        formState: {errors}
    } = useForm<UserSearchParams>({});

    const [
        getUsers,
        {
            data: usersData,
            loading: usersLoading,
            error: usersError
        }
    ] = useGetUsersLazyQuery();

    const findUsers = useCallback((searchParams: UserSearchParams) => {
        setError(undefined);
        getUsers({
            variables: {
                userSearchParams: {
                    ...searchParams,
                    id: getNumber(searchParams.id)
                },
            },
            fetchPolicy: "no-cache"
        })
            .then(result => {
                const users = result.data?.getUsers;
                if (!!users && users.length > 0) {
                    setSelectedUser(users[0]);
                    return;
                } else {
                    setError(`Could not find any users matching the search criteria`);
                }
            })
            .catch(error => {
                let message = `Error retrieving users. Message: ${error.message}`;
                sendNotice(message);
                setError(message);
            });
    }, [getUsers, sendNotice]);

    const currentUser = user || signedInUser;
    const selectableUsers = usersData?.getUsers;

    const firstUser = getFirstUserInList(selectableUsers);

    const udpateUser = useCallback((userId: string | number | null | undefined) => {

        const user = getUserForId(userId, selectableUsers);
        if (!user) {
            sendNotice(`User object not retrieved for userId: ${userId}`);
        } else {
            setSelectedUser(user);
        }
    }, [sendNotice, selectableUsers]);

    const selectChosenUser = useCallback(() => {
        if (!selectedUser) {
            return;
        }
        setUser(selectedUser);
        close();
    }, [close, selectedUser, setUser]);

    return <Dialog
        open={open}
    >
        <DialogTitle>
            <Grid container>
                <Grid item xs={12}>
                    Current user: {currentUser?.firstName} {currentUser?.lastName} ({currentUser?.email})
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{mt: 5}}>
                User search
            </Grid>
        </DialogTitle>
        <DialogContent>
            <form onSubmit={handleSubmit(findUsers)}>
                <Grid container alignItems="center" spacing={1.5}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Id"}
                            {...register("id")}
                            error={!!errors.id}
                            helperText={errors.id?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={"First name"}
                            {...register("firstName")}
                            error={!!errors.firstName}
                            helperText={errors.firstName?.message}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={"Last name"}
                            {...register("lastName")}
                            error={!!errors.lastName}
                            helperText={errors.lastName?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Email"}
                            {...register("email")}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label={"Auth0 ID"}
                            {...register("auth0Id")}
                            error={!!errors.auth0Id}
                            helperText={errors.auth0Id?.message}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            fullWidth
                            label={"Mobile"}
                            {...register("mobile")}
                            error={!!errors.mobile}
                            helperText={errors.mobile?.message}
                        />
                    </Grid>


                    <Grid item xs={12}>
                        <LoadingErrorDisplay
                            loading={usersLoading}
                            apolloError={usersError}
                            stringError={error}
                        >
                            <Button
                                onClick={() => close()}
                                style={{fontSize: '1.2rem'}}>
                                Cancel
                            </Button>
                            <Button
                                type={"submit"}
                                style={{fontSize: '1.2rem'}}
                            >
                                Search
                            </Button>
                        </LoadingErrorDisplay>
                    </Grid>
                </Grid>
            </form>
            <Divider sx={{mt: 2, mb: 0}}/>
            {
                !!selectableUsers && selectableUsers.length > 0 &&
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Select
                            sx={{mt: 2}}
                            value={!!selectedUser ? selectedUser.id : firstUser?.id}
                            label="User"
                            onChange={event => udpateUser(event.target.value)}
                        >
                            {
                                selectableUsers?.map(
                                    user => {
                                        return (
                                            <MenuItem
                                                key={user.id || 0}
                                                value={user.id || 0}
                                            >
                                                {user.firstName} {user.lastName} ({user.email})
                                            </MenuItem>
                                        )
                                    }
                                )
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                        <LoadingErrorDisplay
                            loading={usersLoading}
                            apolloError={usersError}
                            stringError={error}
                        >
                            <Button
                                type={"button"}
                                variant={"contained"}
                                style={{fontSize: '1.2rem'}}
                                onClick={() => selectChosenUser()}
                            >
                                Update user
                            </Button>
                        </LoadingErrorDisplay>
                    </Grid>
                </Grid>
            }
        </DialogContent>

    </Dialog>

}
export default UserSearch;